import img1 from "../Assets/handloom1.jpg"
import img2 from "../Assets/handloom2.jpg"
import img3 from "../Assets/handloom3.jpg"
import img4 from "../Assets/handmade1.jpg"
import img5 from "../Assets/abstract2.jpg"
import img6 from "../Assets/abstract3.jpg"
import img7 from "../Assets/durry1.jpg"
import img8 from "../Assets/durry2.jpg"
import img9 from "../Assets/durry3.jpg"
import img10 from "../Assets/1abstract.jpg"
import img11 from "../Assets/2abstract.jpg"
import img12 from "../Assets/3abstract.jpg"
import img13 from "../Assets/jacquard1.jpg"
import img14 from "../Assets/jacquard2.jpg"
import img15 from "../Assets/jacquard3.jpg"
import img16 from "../Assets/jute1.png"
import img17 from "../Assets/jute2.jpg"
import img18 from "../Assets/jute3.jpg"
import img19 from "../Assets/jute4.jpg"
import img20 from "../Assets/tibetan1.jpg"
import img21 from "../Assets/tibetan2.jpg"
import img22 from "../Assets/tibetan3.jpg"
import img23 from "../Assets/vintage1.jpg"
import img24 from "../Assets/vintage2.jpg"
import img25 from "../Assets/vintage3.jpg"
import img26 from "../Assets/sareesilk1.jpg"
import img27 from "../Assets/sareesilk2.jpg"
import img28 from "../Assets/sareesilk3.jpg"
import img29 from "../Assets/moroccan1.jpg"
import img30 from "../Assets/moroccan2.jpg"
import img31 from "../Assets/moroccan3.jpg"
import img32 from "../Assets/leather1.jpg"
import img33 from "../Assets/leather2.jpg"
import img34 from "../Assets/leather3.jpg"
import img35 from "../Assets/kelim1.jpg"
import img36 from "../Assets/kelim2.jpg"
import img37 from "../Assets/kelim3.jpg"
import img38 from "../Assets/print1.jpg"
import img39 from "../Assets/print2.jpg"
import img40 from "../Assets/print3.jpg"
import img41 from "../Assets/high-low1.jpg"
import img42 from "../Assets/high-low2.jpg"
import img43 from "../Assets/high-low3.jpg"




const products = [
    {
      id: 1,
      category: 'Handloom Viscose Collection',
      name: 'HV01',
      price: 499.99,
      
      image:img1,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala'
    },
    {
      id: 2,
      category: 'Handloom Viscose Collection',
      name: 'HV02',
      price: 499.99,
      
      image:img2,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala'
    },
    {
      id: 3,
      category: 'Handloom Viscose Collection',
      name: 'HV03',
      price: 499.99,
      
      image:img3,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala'
    },
    {
      id: 4,
      category: 'Handknotted Collection',
      name: 'HK01',
      price: 899.99,
      image: img4,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    {
      id: 5,
      category: 'Handknotted Collection',
      name: 'HK02',
      price: 899.99,
      image: img5,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    {
      id: 6,
      category: 'Handknotted Collection',
      name: 'HK03',
      price: 899.99,
      image: img6,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    
    {
      id: 7,
      category: 'Durry Collection',
      name: 'DR01',
      price: 19.99,
      image: img7,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    {
      id: 8,
      category: 'Durry Collection',
      name: 'DR02',
      price: 19.99,
      image: img8,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    {
      id: 9,
      category: 'Durry Collection',
      name: 'DR03',
      price: 19.99,
      image: img9,
      description: 'Special Woven handmade Carpet from italy designer made from hands isko laga dala to life jhingalala',
    },
    {
      id: 10,
      category: 'Abstract Collection',
      name: 'AC01',
      price: 39.99,
      image: img10,
    },
    {
      id: 11,
      category: 'Abstract Collection',
      name: 'AC02',
      price: 39.99,
      image: img11,
    },
    {
      id: 12,
      category: 'Abstract Collection',
      name: 'AC03',
      price: 39.99,
      image: img12,
    },
    {
        id: 13,
        category: 'Jacquard Collection',
        name: 'JC01',
        price: 499.99,
        image: img13,
      },
      {
        id: 14,
        category: 'Jacquard Collection',
        name: 'JC02',
        price: 499.99,
        image: img14,
      },
      {
        id: 15,
        category: 'Jacquard Collection',
        name: 'JC03',
        price: 499.99,
        image: img15,
      },

      {
        id: 16,
        category: 'Jute Collection',
        name: 'JT01',
        price: 899.99,
        image: img16,
      },
      {
        id: 17,
        category: 'Jute Collection',
        name: 'JT02',
        price: 899.99,
        image: img17,
      },
      {
        id: 18,
        category: 'Jute Collection',
        name: 'JT03',
        price: 899.99,
        image: img18,
      },
      {
        id: 19,
        category: 'Jute Collection',
        name: 'JT04',
        price: 899.99,
        image: img19,
      },
      {
        id: 20,
        category: 'Tibetan Collection',
        name: 'TC01',
        price: 19.99,
        image: img20,
      },
      {
        id: 21,
        category: 'Tibetan Collection',
        name: 'TC02',
        price: 19.99,
        image: img21,
      },
      {
        id: 22,
        category: 'Tibetan Collection',
        name: 'TC03',
        price: 19.99,
        image: img22,
      },
      {
        id: 23,
        category: 'Vintage Collection',
        name: 'VC01',
        price: 39.99,
        image: img23,
      },
      {
        id: 24,
        category: 'Vintage Collection',
        name: 'VC02',
        price: 39.99,
        image: img24,
      },
      {
        id: 25,
        category: 'Vintage Collection',
        name: 'VC03',
        price: 39.99,
        image: img25,
      },
      {
        id: 26,
        category: 'Sari Silk Collection',
        name: 'SS01',
        price: 39.99,
        image: img26,
      },
      {
        id: 27,
        category: 'Sari Silk Collection',
        name: 'SS02',
        price: 39.99,
        image: img27,
      },
      {
        id: 28,
        category: 'Sari Silk Collection',
        name: 'SS03',
        price: 39.99,
        image: img28,
      },{
        id: 29,
        category: 'Moroccan Collection',
        name: 'MR01',
        price: 39.99,
        image: img29,
      },
      {
        id: 30,
        category: 'Moroccan Collection',
        name: 'MR02',
        price: 39.99,
        image: img30,
      },
      {
        id: 31,
        category: 'Moroccan Collection',
        name: 'MR03',
        price: 39.99,
        image: img31,
      },
      {
        id: 32,
        category: 'Leather Collection',
        name: 'LC01',
        price: 39.99,
        image: img32,
      },
      {
        id: 33,
        category: 'Leather Collection',
        name: 'LC02',
        price: 39.99,
        image: img33,
      },
      {
        id: 34,
        category: 'Leather Collection',
        name: 'LC03',
        price: 39.99,
        image: img34,
      },
      {
        id: 35,
        category: 'Kelims Collection',
        name: 'KL01',
        price: 39.99,
        image: img35,
      },
      {
        id: 36,
        category: 'Kelims Collection',
        name: 'KL02',
        price: 39.99,
        image: img36,
      },
      {
        id: 37,
        category: 'Kelims Collection',
        name: 'KL03',
        price: 39.99,
        image: img37,
      },
      
      {
        id: 41,
        category: 'Hi-Low Collection',
        name: 'HL01',
        price: 39.99,
        image: img41,
      },
      {
        id: 42,
        category: 'Hi-Low Collection',
        name: 'HL02',
        price: 39.99,
        image: img42,
      },
      {
        id: 43,
        category: 'Hi-Low Collection',
        name: 'HL03',
        price: 39.99,
        image: img43,
      },
      {
        id: 38,
        category: 'Print Collection',
        name: 'PC01',
        price: 39.99,
        image: img38,
      },
      {
        id: 39,
        category: 'Print Collection',
        name: 'PC02',
        price: 39.99,
        image: img39,
      },
      {
        id: 40,
        category: 'Print Collection',
        name: 'PC03',
        price: 39.99,
        image: img40,
      },
    // Add more products and categories here
  ];
  
  export default products;