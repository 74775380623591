import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { AiOutlineMobile, AiOutlineMail } from "react-icons/ai";
import { BsPerson } from "react-icons/bs";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number:'',
    message: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs
      .send(
        'service_syqn784',
        'template_hgu5ch1',
        formData,
        'z8_VCcVxfgzHemqTI'
      )
      .then(
        (response) => {
          console.log('Email sent:', response);
          alert('Email sent successfully!');
          console.log(formData)
        },
        (error) => {
          console.error('Error sending email:', error);
          alert('Failed to send email. Please try again later.');
        }
      );
  };
  return (
    <div className="form">
      <form onSubmit={handleSubmit}>
      <div className="same">
        <div><div className="icon">
                  <BsPerson />
                </div>
                <input
        type="text"
        name="name"
        required placeholder=" Your Name"
        value={formData.name}
        onChange={handleChange}
      /></div>
     <div><div className="icon">
                  <AiOutlineMail />
                </div>
                <input
        type="email"
        name="email"
        required placeholder="Your Email"
        value={formData.email}
        onChange={handleChange}
      /></div>
      <div> <div className="icon">
                  <AiOutlineMobile />
                </div> <input
        type="tel"
        name="tel"
        required placeholder="Your Number"
        value={formData.tel}
        onChange={handleChange}
      /></div>
     
      </div>
      <div><textarea
      id="freeform"
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleChange}
        rows="20"
        cols="200"
      ></textarea></div>
      
      <button type="submit" className="btn-grad">Send Message</button>
    </form>
    </div>
    
  );
}

export default ContactForm;