import React from 'react';
import { NavLink } from 'react-router-dom';

function ProductCard({ product }) {
  return (
    <NavLink to={'/Singleproduct/${id}'}>
        <div className="product-card">
      <img src={product.image} alt={product.name} />
      <h3>{product.name}</h3>
      
    </div>
    </NavLink>
  
  );
}

export default ProductCard;